import React from 'react';
import qs from 'qs';
import { Button, Col, Container, Row, Table } from 'react-bootstrap';
import { Link, Route, useRouteMatch } from 'react-router-dom';
import {
  IBusiness,
  useBusinessesSimple,
  useUpdateBusiness,
} from '../state/modules/businesses';
import TenantSelector from '../components/businesses/TenantSelector';
import { IXeroTenant } from '../state/modules/xeroTenant';
import { RequestStatus } from '../common/types';
import ManualSyncJob from '../components/businesses/ManualSyncJob';
import { useCreateManualSyncJob } from '../state/modules/manualSyncJobs';
import ReportsModal from '../components/businesses/ReportsModal';

function getLinkButtonText(xeroStatus: string): string {
  return (
    { UNLINKED: 'Link to Xero', EXPIRED: 'Re-Link to Xero' }[xeroStatus] ||
    'Retry Link to Xero'
  );
}

function Businesses() {
  const { payload: businesses, status: businessesStatus } =
    useBusinessesSimple();
  const updateBusiness = useUpdateBusiness();
  const createManualSyncJob = useCreateManualSyncJob();
  const { path } = useRouteMatch();

  return (
    <Container fluid>
      <Row>
        <Col xs={12}>
          <Table bordered>
            <thead>
              <tr>
                <th>Business</th>
                <th>Status</th>
                <th>Link</th>
                <th>Download</th>
                <th>Organization</th>
                <th>Jobs</th>
              </tr>
            </thead>
            <tbody>
              {businesses?.length
                ? businesses.map((business: IBusiness) => (
                    <tr key={business.recordId2}>
                      <td>{business.businessName}</td>
                      <td>{business.xeroStatus || 'UNLINKED'}</td>
                      <td>
                        <Button
                          variant="primary"
                          href={`${
                            process.env.REACT_APP_API_DOMAIN
                          }/api/v1/xero-connections/initiate?${qs.stringify({
                            rid: business.recordId2,
                            name: business.businessName,
                          })}`}
                        >
                          {getLinkButtonText(business.xeroStatus || 'UNLINKED')}
                        </Button>
                      </td>
                      <td>
                        <Link to={`/reports/${business.recordId2}`}>
                          <Button
                            variant="primary"
                            disabled={business.xeroStatus !== 'LINKED'}
                          >
                            Xero Reports
                          </Button>
                        </Link>
                      </td>
                      <td>
                        <TenantSelector
                          business={business}
                          loading={businessesStatus === RequestStatus.LOADING}
                          onChangeTenant={(tenant: IXeroTenant) =>
                            updateBusiness(business.recordId2, tenant)
                          }
                        />
                      </td>
                      <td>
                        <ManualSyncJob
                          business={business}
                          loading={false}
                          onClick={() =>
                            createManualSyncJob({ rid: business.recordId2 })
                          }
                        />
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </Table>
          <Route path={`${/\/$/.test(path) ? path : `${path}/`}reports/:id`}>
            <ReportsModal />
          </Route>
        </Col>
      </Row>
    </Container>
  );
}

export default Businesses;
