import { Reducer } from 'react';
import qs from 'qs';

import { IAction, IAPIErrorFormat, RequestStatus } from '../../../common/types';
import { isDefined } from '../../../utils/typeGuards';
import { useApiWithIDToken, useDispatch, useState } from '../common';
import { isEmpty } from '../../../utils/object';

export interface ISliceState<T> {
  status: RequestStatus;
  payload: T;
  httpErrors?: IAPIErrorFormat;
}

export default function createSlice<T>(
  name: string,
  url: string = `/${name}`,
  //@ts-ignore
  defaultPayload: T = []
) {
  // Types & Interfaces
  interface ISliceState {
    status: RequestStatus;
    payload: T;
    httpErrors?: IAPIErrorFormat;
  }

  // Initial state
  const initialState: ISliceState = {
    status: RequestStatus.IDLE,
    payload: defaultPayload,
  };

  // Actions
  const REQUEST_LOADING = `${name}/REQUEST_LOADING`;
  const REQUEST_LOADED = `${name}/REQUEST_LOADED`;
  const REQUEST_WITH_QUERY_LOADED = `${name}/REQUEST_WITH_QUERY_LOADED`;
  const REQUEST_FAILED = `${name}/REQUEST_FAILED`;
  const HTTP_ERRORS_DISMISSED = `${name}/HTTP_ERRORS_DISMISSED`;

  // Slice reducers
  const reducer: Reducer<ISliceState, IAction> = (
    state = initialState,
    { type, payload, query }
  ) => {
    switch (type) {
      case REQUEST_LOADING:
        return {
          ...state,
          status: RequestStatus.LOADING,
        };
      case REQUEST_LOADED:
        return {
          ...state,
          status: RequestStatus.SUCCEEDED,
          payload: isDefined(payload) ? payload : state.payload,
          httpErrors: undefined,
        };
      case REQUEST_WITH_QUERY_LOADED:
        // @TODO - This will need other states, other than success.
        return {
          ...state,
          [qs.stringify(query)]: {
            status: RequestStatus.SUCCEEDED,
            payload: isDefined(payload) ? payload : state.payload,
            httpErrors: undefined,
          },
        };
      case REQUEST_FAILED:
        return {
          ...state,
          status: RequestStatus.FAILED,
          httpErrors: payload,
        };
      case HTTP_ERRORS_DISMISSED:
        return {
          ...state,
          httpErrors: undefined,
        };
      default:
        return state;
    }
  };

  // Action creators
  const requestLoading = () => {
    return { type: REQUEST_LOADING };
  };

  const requestLoaded = (payload?: T[]) => {
    return { type: REQUEST_LOADED, payload };
  };

  const requestWithQueryLoaded = (
    payload?: T[],
    query?: Record<string, unknown>
  ) => {
    return { type: REQUEST_WITH_QUERY_LOADED, payload, query };
  };

  const requestFailed = (error: IAPIErrorFormat) => {
    return { type: REQUEST_FAILED, payload: error };
  };

  const httpErrorsDismissed = () => {
    return { type: HTTP_ERRORS_DISMISSED };
  };

  // API Selectors Hooks
  // @ts-ignore
  const useSlice = () => useState()[name];

  const useSliceWithQuery = (query?: Record<string, unknown>) => {
    // @ts-ignore
    const sliceState = useState()[name];
    return isEmpty(query) ? sliceState : sliceState[qs.stringify(query)];
  };

  // API Actions Hooks
  const useLoadSlicePayload = (query?: Record<string, unknown>) => {
    const dispatch = useDispatch();
    const apiWithIDToken = useApiWithIDToken();

    return async (requestQuery?: Record<string, unknown>) => {
      const chosenQuery: Record<string, unknown> | null | undefined = (() => {
        if (!isEmpty(requestQuery)) {
          return requestQuery;
        } else if (!isEmpty(query)) {
          return query;
        } else {
          return null;
        }
      })();

      dispatch(requestLoading());
      try {
        const urlWithQuery: string = chosenQuery
          ? `${url}?${qs.stringify(chosenQuery)}`
          : url;
        const { data } = await apiWithIDToken.get(urlWithQuery);
        dispatch(
          chosenQuery
            ? requestWithQueryLoaded(data as T[], chosenQuery)
            : requestLoaded(data as T[])
        );
      } catch (error: any) {
        const apiError = (error?.response?.data || error) as IAPIErrorFormat;
        dispatch(requestFailed(apiError));
      }
    };
  };

  const useDismissHTTPErrors = () => {
    const dispatch = useDispatch();
    return () => {
      dispatch(httpErrorsDismissed());
    };
  };

  return {
    initialState,
    REQUEST_LOADING,
    REQUEST_LOADED,
    REQUEST_FAILED,
    HTTP_ERRORS_DISMISSED,
    requestLoading,
    requestLoaded,
    requestFailed,
    httpErrorsDismissed,
    reducer,
    useSlice,
    useSliceWithQuery,
    useLoadSlicePayload,
    useDismissHTTPErrors,
  };
}
