import {
  initialState as businessesInitialState,
  sliceName as businessesSliceName,
} from './modules/businesses';
import {
  initialState as notificationsInitialState,
  sliceName as notificationsSliceName,
} from './modules/notifications';
import {
  initialState as confirmationInitialState,
  sliceName as confirmationSliceName,
} from './modules/confirmation';
import {
  initialState as authInitialState,
  sliceName as authSliceName,
} from './modules/auth';

const initialState = {
  [businessesSliceName]: businessesInitialState,
  [confirmationSliceName]: confirmationInitialState,
  [notificationsSliceName]: notificationsInitialState,
  [authSliceName]: authInitialState,
};

export type IState = typeof initialState;

export default initialState;
