import createSlice, { ISliceState } from './helpers/createSlice';
import { createSimpleLoadHook } from '../../utils/hooks';

// Types & Interfaces
export interface IXeroTenant {
  tenantId: string;
  tenantName: string;
}

export interface IXeroTenants extends ISliceState<IXeroTenant[]> {}

// Slice Name
export const sliceName = 'xeroTenants';

// Abstract Slice Instance
const xeroTenantsSlice = createSlice<IXeroTenant[]>(sliceName, '/xero-tenants');

// Initial state
export const initialState = xeroTenantsSlice.initialState;

// Slice Selectors Hooks
export const useXeroTenants: () => IXeroTenants = xeroTenantsSlice.useSlice;

export const useXeroTenantsWithQuery: (
  query?: Record<string, unknown>
) => IXeroTenants = xeroTenantsSlice.useSliceWithQuery;

// Slice Actions Hooks
export const useLoadXeroTenants = xeroTenantsSlice.useLoadSlicePayload;

export const useDismissXeroTenantsHTTPErrors =
  xeroTenantsSlice.useDismissHTTPErrors;

export const useXeroTenantsSimple = createSimpleLoadHook<IXeroTenants>(
  useXeroTenants,
  useLoadXeroTenants
);

export const useXeroTenantsSimpleWithQuery = createSimpleLoadHook<IXeroTenants>(
  useXeroTenantsWithQuery,
  useLoadXeroTenants
);

// Slice reducer
export default xeroTenantsSlice.reducer;
