import React, { ChangeEvent } from 'react';
import { IBusiness } from '../../state/modules/businesses';
import {
  IXeroTenant,
  useXeroTenantsSimpleWithQuery,
} from '../../state/modules/xeroTenant';

export interface ITenantSelectorProps {
  business: IBusiness;
  loading: boolean;
  onChangeTenant: Function;
}

function LinkedBusinessTenantSelector({
  business,
  loading,
  onChangeTenant,
}: ITenantSelectorProps) {
  const { payload: xeroTenants } = useXeroTenantsSimpleWithQuery({
    rid: business.recordId2,
  });
  const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const tenant: IXeroTenant | undefined = (xeroTenants || []).find(
      ({ tenantId }) => tenantId === e.target.value
    );
    onChangeTenant(tenant);
  };
  return (
    <select
      className="form-control"
      value={business.tenantId}
      disabled={loading}
      onChange={onChange}
    >
      {(xeroTenants || []).map(({ tenantId, tenantName }) => (
        <option key={tenantId} value={tenantId}>
          {tenantName}
        </option>
      ))}
    </select>
  );
}

function TenantSelector({
  business,
  loading,
  onChangeTenant,
}: ITenantSelectorProps) {
  if (business.xeroStatus === 'LINKED') {
    return (
      <LinkedBusinessTenantSelector
        business={business}
        loading={loading}
        onChangeTenant={onChangeTenant}
      />
    );
  } else {
    return <select className="form-control" disabled={true} />;
  }
}

export default TenantSelector;
