import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './App.css';
import { useNotifications } from './state/modules/notifications';
import ConfirmationModal from './components/ConfirmationModal';
import Header from './containers/Header';
import Login from './containers/Login';
import { useGetIDToken } from './state/modules/auth';
import { useCallOnceOnAuthenticationVerified } from './state/modules/common';
import WithAuthenticationRequired from './state/modules/helpers/WithAuthenticationRequired';
import Businesses from './containers/Businesses';

function App() {
  const { data: notifications, dismiss: dismissNotification } =
    useNotifications();
  const getIDToken = useGetIDToken();

  useCallOnceOnAuthenticationVerified(getIDToken);

  useEffect(() => {
    if (notifications) {
      setTimeout(() => {
        notifications.forEach(({ id }) => dismissNotification(id));
      }, 3000);
    }
  }, [notifications]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Router>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/">
          <ConfirmationModal />
          <Header />
          <Switch>
            <Route path="/">
              <WithAuthenticationRequired Component={Businesses} />
            </Route>
          </Switch>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
