import axios from 'axios';
import { useContext, useEffect } from 'react';
import { isDefined } from '../../utils/typeGuards';

import { store } from '../store';
import { useIDToken, useAuth } from './auth';

const useRootContext = () => useContext(store);

// Delegated state ref from root context
export const useState = () => useRootContext().state;

// Delegated dispatch ref from root context
export const useDispatch = () => useRootContext().dispatch;

// This Hook will invoke the given cb only once component did mount
export const useCallOnce = (cb: () => void) => useEffect(cb, []); //eslint-disable-line react-hooks/exhaustive-deps

// This Hook will listen for id token and the given deps and invoke the cb only if id token is granted
export const useCallOnIDTokenGranted = (cb: () => void, deps: any[] = []) => {
  const idToken = useIDToken();
  useEffect(() => {
    if (idToken) {
      cb();
    }
  }, [idToken, ...deps]); //eslint-disable-line react-hooks/exhaustive-deps
};

// This Hook will listen for id token and invoke the cb once if id token is granted
export const useCallOnceOnIDTokenGranted = (cb: () => void) => {
  useCallOnIDTokenGranted(cb);
};

// This Hook will listen for auth status and invoke the cb once if user is authenticated
export const useCallOnceOnAuthenticationVerified = (cb: () => void) => {
  const { isAuthenticated } = useAuth();
  useEffect(() => {
    if (isAuthenticated) {
      cb();
    }
  }, [isAuthenticated]); //eslint-disable-line react-hooks/exhaustive-deps
};

// This Hook will listen for the given list and invoke the cb if list is not empty
export const useCallOnHasElements = (cb: () => void, list: any[]) => {
  useEffect(() => {
    if (isDefined(list) && list.length !== 0) {
      cb();
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [list]);
};

export const baseURL = '/api/v1';

// This Hook will return an axios instance with baseUrl applied
export const useApi = () => {
  return axios.create({ baseURL });
};

// This Hook will return an axios instance with Authorization header & baseUrl applied
export const useApiWithIDToken = () => {
  const accessToken = useIDToken();
  return axios.create({
    baseURL,
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};
