import createSlice, { ISliceState } from './helpers/createSlice';
import { createSimpleLoadHook } from '../../utils/hooks';
import { useApiWithIDToken, useDispatch } from './common';
import { IAPIErrorFormat } from '../../common/types';

// Types & Interfaces
export interface IBusinessUpdate {
  tenantId: string;
  tenantName: string;
}

export interface IBusiness extends IBusinessUpdate {
  recordId2: number;
  businessName: string;
  xeroStatus: string;
}

export interface IBusinesses extends ISliceState<IBusiness[]> {}

// Slice Name
export const sliceName = 'businesses';
export const url = `/${sliceName}`;

// Abstract Slice Instance
const businessesSlice = createSlice<IBusiness[]>(sliceName, url);

// Initial state
export const initialState = businessesSlice.initialState;

// Slice Selectors Hooks
export const useBusinesses: () => IBusinesses = businessesSlice.useSlice;

// Slice Actions Hooks
export const useLoadBusinesses = businessesSlice.useLoadSlicePayload;

export const useDismissBusinessesHTTPErrors =
  businessesSlice.useDismissHTTPErrors;

export const useBusinessesSimple = createSimpleLoadHook<IBusinesses>(
  useBusinesses,
  useLoadBusinesses
);

export const useBusinessesById = (id: number | undefined) => {
  const { payload: businesses } = useBusinesses();
  return (businesses || []).filter(
    ({ recordId2 }) => recordId2 && id && String(recordId2) === String(id)
  )?.[0];
};

export function useUpdateBusiness() {
  const dispatch = useDispatch();
  const apiWithIDToken = useApiWithIDToken();
  const loadBusinesses = useLoadBusinesses();
  return async (id: number, body: IBusinessUpdate) => {
    try {
      dispatch(businessesSlice.requestLoading());
      await apiWithIDToken.put(`${url}/${id}`, body);
      dispatch(businessesSlice.requestLoaded());

      // NOTE - We reload here, not sure if it's the very best place.
      await loadBusinesses();
    } catch (error) {
      dispatch(businessesSlice.requestFailed(error as IAPIErrorFormat));
    }
  };
}

// Slice reducer
export default businessesSlice.reducer;
