import React from 'react';
import { Button, ListGroup, ListGroupItem, Modal } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { parseIntegerOrUndefined } from 'deep-cuts';
import { useBusinessesById } from '../../state/modules/businesses';
import { useApiWithIDToken } from '../../state/modules/common';
import { fromGetRequest as fileDownloadFromGetRequest } from '../../utils/download';

export interface IReportsModalParams {
  id: string;
}

function ReportsModal() {
  const { id } = useParams<IReportsModalParams>();
  const history = useHistory();
  const business = useBusinessesById(parseIntegerOrUndefined(id));
  const api = useApiWithIDToken();

  const downloadReportFactory = (reportType: string) => async () => {
    const response = await api.get(
      `/businesses/${business.recordId2}/xero-reports/${reportType}/download`,
      { responseType: 'blob' }
    );
    return fileDownloadFromGetRequest(response);
  };
  const onCancel = () => history.push('..');

  const content = business?.businessName ? (
    <>
      <Modal.Header>
        <Modal.Title>Xero Reports ({business.businessName})</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroup>
          <ListGroupItem>
            <Button
              variant="link"
              onClick={downloadReportFactory('earnings-rates')}
            >
              Earnings Rates
            </Button>
          </ListGroupItem>
          <ListGroupItem>
            <Button
              variant="link"
              onClick={downloadReportFactory('leave-types')}
            >
              Leave Types
            </Button>
          </ListGroupItem>
        </ListGroup>
      </Modal.Body>
    </>
  ) : null;

  return (
    <Modal onHide={onCancel} animation={false} show={true} centered>
      {content}
    </Modal>
  );
}

export default ReportsModal;
