import React from 'react';
import { Alert, Container, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { useAuth, useCallOnIsAuthenticated } from '../state/modules/auth';
import { ScreenLoader } from '../components/common/Loaders';

function Login() {
  const history = useHistory();
  const { isLoading, loginWithRedirect, error } = useAuth();

  useCallOnIsAuthenticated(() => {
    history.goBack();
  }, [history]);

  //useCallOnce(loginWithRedirect); // Opens the popup once the component loads

  if (error?.message === 'user is blocked') {
    error.message =
      'Login failed, Please contact Haven Equity about your account.';
  }

  return (
    <div className="fixed-top bg-light h-100 w-100 d-flex justify-content-center align-items-center">
      <Container className="row d-flex flex-column align-items-center justify-content-center flex-wrap">
        <img
          src={`${process.env.PUBLIC_URL}/logo.png`}
          alt="logo"
          className="mb-2"
          style={{ width: '370px' }}
        />
        {isLoading ? (
          <ScreenLoader />
        ) : (
          <>
            {error ? (
              <Alert variant={'danger'} className="col-4 mb-2">
                {error.message || 'Unexpected error'}
              </Alert>
            ) : (
              <p className="text-center font-italic col-4">
                Building Operational Resilience
              </p>
            )}
            <Button
              className="col-4 mt-2 btn-lg"
              onClick={() => loginWithRedirect()}
            >
              {error ? 'Try Again' : 'Login'}
            </Button>
          </>
        )}
      </Container>
    </div>
  );
}

export default Login;
