import createSlice, { ISliceState } from './helpers/createSlice';
import { createSimpleLoadHook } from '../../utils/hooks';
import { useApiWithIDToken, useDispatch } from './common';
import { IAPIErrorFormat } from '../../common/types';

// Types & Interfaces
interface IBusinessIdentifier {
  rid: number;
  name: string;
}

export interface IManualSyncJob {
  business: IBusinessIdentifier;
  created: string;
  completed: string;
}

export interface IManualSyncJobCreate {
  rid: number;
}

export interface IManualSyncJobs extends ISliceState<IManualSyncJob[]> {}

// Slice Name
export const sliceName = 'manualSyncJobs';
export const url = '/manual-sync-jobs';

// Abstract Slice Instance
const manualSyncJobsSlice = createSlice<IManualSyncJob[]>(sliceName, url);

// Initial state
export const initialState = manualSyncJobsSlice.initialState;

// Slice Selectors Hooks
export const useManualSyncJobs: () => IManualSyncJobs =
  manualSyncJobsSlice.useSlice;

export const useManualSyncJobsWithQuery: (
  query?: Record<string, unknown>
) => IManualSyncJobs = manualSyncJobsSlice.useSliceWithQuery;

// Slice Actions Hooks
export const useLoadManualSyncJobs = manualSyncJobsSlice.useLoadSlicePayload;

export const useDismissManualSyncJobsHTTPErrors =
  manualSyncJobsSlice.useDismissHTTPErrors;

export const useManualSyncJobsSimple = createSimpleLoadHook<IManualSyncJobs>(
  useManualSyncJobs,
  useLoadManualSyncJobs
);

export const useManualSyncJobsSimpleWithQuery =
  createSimpleLoadHook<IManualSyncJobs>(
    useManualSyncJobsWithQuery,
    useLoadManualSyncJobs
  );

export function useCreateManualSyncJob() {
  const dispatch = useDispatch();
  const apiWithIDToken = useApiWithIDToken();
  const loadManualSyncJobs = useLoadManualSyncJobs();
  return async (body: IManualSyncJobCreate) => {
    try {
      dispatch(manualSyncJobsSlice.requestLoading());
      await apiWithIDToken.post(url, body);
      dispatch(manualSyncJobsSlice.requestLoaded());

      // NOTE - We reload here, not sure if it's the very best place.
      await loadManualSyncJobs({ rid: body.rid });
    } catch (error) {
      dispatch(manualSyncJobsSlice.requestFailed(error as IAPIErrorFormat));
    }
  };
}

// Slice reducer
export default manualSyncJobsSlice.reducer;
