import React from 'react';
import { IBusiness } from '../../state/modules/businesses';
import { Button } from 'react-bootstrap';
import { useManualSyncJobsSimpleWithQuery } from '../../state/modules/manualSyncJobs';
import moment from 'moment';

export interface IManualSyncJobProps {
  business: IBusiness;
  loading: boolean;
  onClick: Function;
}

function LinkedBusinessManualSyncJob({
  business,
  onClick,
}: IManualSyncJobProps) {
  const { payload: manualSyncJobs } = useManualSyncJobsSimpleWithQuery({
    rid: business.recordId2,
  });
  return (
    <>
      <div>
        <Button variant="primary" onClick={() => onClick()}>
          Start Manual Sync
        </Button>
      </div>
      {manualSyncJobs?.length ? (
        <div>
          <i>
            Job in progress, started at{' '}
            {moment(manualSyncJobs[0].created).format('H:mm')}
          </i>
        </div>
      ) : null}
    </>
  );
}

function ManualSyncJob({ business, loading, onClick }: IManualSyncJobProps) {
  if (business.xeroStatus === 'LINKED' && !loading) {
    return (
      <LinkedBusinessManualSyncJob
        business={business}
        loading={loading}
        onClick={onClick}
      />
    );
  } else {
    return (
      <Button variant="primary" disabled={true}>
        Start Manual Sync
      </Button>
    );
  }
}

export default ManualSyncJob;
