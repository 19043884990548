import { AxiosResponse } from 'axios';

const DEFAULT_CSV_DOWNLOAD_NAME = 'download.csv';

function fileNameFromHeaders(headers: any) {
  const contentDisposition = headers?.['content-disposition'];
  if (contentDisposition) {
    return (
      contentDisposition.match(/filename="(.*)"/)?.[1] ||
      DEFAULT_CSV_DOWNLOAD_NAME
    );
  }
  return DEFAULT_CSV_DOWNLOAD_NAME;
}

export function fromGetRequest(response: AxiosResponse) {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileNameFromHeaders(response.headers)); //or any other extension
  document.body.appendChild(link);
  link.click();
}
