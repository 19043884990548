import { Reducer } from 'react';

import businessesReducer, {
  sliceName as businessesSliceName,
} from './modules/businesses';
import confirmationReducer, {
  sliceName as confirmationSliceName,
} from './modules/confirmation';
import manualSyncJobsReducer, {
  sliceName as manualSyncJobsReducerSliceName,
} from './modules/manualSyncJobs';
import notificationsReducer, {
  sliceName as notificationsSliceName,
} from './modules/notifications';
import authReducer, { sliceName as authSliceName } from './modules/auth';
import xeroTenantsReducer, {
  sliceName as xeroTenantsSliceName,
} from './modules/xeroTenant';

import { IAction } from '../common/types';
import { IState } from './initialState';

function combineReducers<S>(reducers: {
  [key: string]: Reducer<any, IAction>;
}) {
  return (state: S, action: IAction): S => {
    // @ts-ignore
    return Object.keys(reducers).reduce((newState: object, key: K) => {
      return {
        ...newState,
        // @ts-ignore
        [key]: reducers[key](state[key], action),
      };
    }, {});
  };
}

const reducer = combineReducers<IState>({
  [confirmationSliceName]: confirmationReducer,
  [manualSyncJobsReducerSliceName]: manualSyncJobsReducer,
  [notificationsSliceName]: notificationsReducer,
  [authSliceName]: authReducer,
  [businessesSliceName]: businessesReducer,
  [xeroTenantsSliceName]: xeroTenantsReducer,
});

export default reducer;
