import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth, useCallOnNotAuthenticated } from '../auth';
import { WithAuthenticationRequiredOptions } from '@auth0/auth0-react';
import { ComponentType } from 'react';
import { ScreenLoader } from '../../../components/common/Loaders';

export default function WithAuthenticationRequired<P extends object>({
  Component,
  props,
  options,
}: {
  Component: ComponentType<P> | ComponentType;
  props?: P;
  options?: WithAuthenticationRequiredOptions;
}): JSX.Element {
  const history = useHistory();
  const { isAuthenticated, isLoading } = useAuth();
  const { returnTo = '/login' } = options || {};

  useCallOnNotAuthenticated(() => {
    history.push(returnTo as string);
  }, [returnTo, history]);

  if (isLoading || !isAuthenticated) {
    return <ScreenLoader />;
  } else if (props) {
    return <Component {...props} />;
  } else {
    // @ts-ignore
    return <Component />;
  }
}
